<template>
	<div class="main person higtlight">
		<TextLoading v-if="loading"></TextLoading>

		<template v-else>
			<div class="leftinfo">
				<div class="info-top">
					<img
						:src="user.avatar_url">
					<!-- <img :src="`https://avatars.dicebear.com/v2/identicon/id-${user.id}.svg`" alt=""> -->
					<!-- <img src="../../../assets/avatar/009.jpg" alt="" v-else> -->
					<div class="top-text">
						<p class="name">{{user.name}}</p>
						<p>第<span class="pink">{{user.id}}</span>位注册的用户</p>
						<p>注册于<span class="time">{{user.create_time.substring(0,10)}}</span></p>
					</div>
				</div>

				<ul>
					<li>邮箱： {{user.email}}</li>
					<li>权限： {{user.admin ? '博主' : '普通用户'}}</li>
					<li  class="link" style="display: flex; justify-content:space-between;">
						修改头像： 
						 <Upload :action="url" :show-upload-list='false'  :on-success="handleSuccess" :headers="{'Authorization':'Bearer ' + token}">
						        <Button  icon="ios-cloud-upload-outline">上传头像</Button>
						</Upload>
					</li>
					<li class="link" @click="$router.push('/password')">修改密码</li>
					
					<!-- <li>
            我的评论<span class="pink"> ({{comments.length}})</span>
          </li>
          <li>
            我的留言<span class="pink"> ({{messages.length}})</span>
          </li> -->
				</ul>
			</div>

			<div class="rightmain">
				<div class="comment">
					<h6>我的评论<span class="pink" v-if="comments"> ({{pageModel1.total}})</span></h6>
					<ul>
						<li v-for="(item, index) in comments" :key="index">
							<router-link :to="{path:`/blog/${item.article.id}`}">评论文章：{{item.article.title}}
							</router-link>
							<div class="mark" v-html="item.content" v-highlight></div>
							<p class="time">
								<Icon type="md-time" />{{item.create_time}}
							</p>
							<div class="delete">
								<Poptip confirm placement="left" title="是否删除该评论?" @on-ok="deleteComment(item)">
									<Icon type="md-trash" />
								</Poptip>
							</div>
						</li>
						<NewPage :pageModel="pageModel1" @selectList="selectCommentList" v-if="pageModel1.total>pageModel1.list_rows">
						</NewPage>
					</ul>
					<ul class="noneli" v-if="pageModel1.total==0">
						你还没有评论
					</ul>
				</div>

				<div class="message">
					<h6>我的留言<span class="pink" v-if="messages"> ({{pageModel2.total}})</span></h6>
					<ul>
						<li v-for="(item, index) in messages" :key="index">
							<div class="mark" v-html="item.content" v-highlight></div>
							<p class="time">
								<Icon type="md-time" />{{item.create_time}}
							</p>
							<div class="delete">
								<Poptip confirm placement="left" title="是否删除该留言?" @on-ok="deleteMessage(item)">
									<Icon type="md-trash" />
								</Poptip>
							</div>
						</li>
						<NewPage :pageModel="pageModel2" @selectList="selectMessageList" v-if="pageModel2.total>pageModel2.list_rows">
						</NewPage>
					</ul>
					<ul class="noneli" v-if="pageModel2.total==0">
						你还没有留言
					</ul>
				</div>
			</div>
		</template>
	</div>

</template>


<script>
	import marked from 'marked'
	import { mapActions, mapGetters } from 'vuex'


	export default {
		data() {
			return {
				loading: false,
				comments: [],
				messages: [],
				pageModel1: {
					page: 1,
					list_rows: 4,
					total:0
				},
				pageModel2: {
					page: 1,
					list_rows: 4,
					total:0
				},
				
				url:process.env.VUE_APP_BASRURL+'/user/updateAvatar',
				
			}
		},
		computed: mapGetters([
			'user','token'
		]),
		created() {
			 this.getComment()
			  this.getMessage()
		},
		
		 
		
		methods: {
			 ...mapActions(['GetUser']),
			getComment() {
				let param = {
					page:this.pageModel1.page,
					list_rows:this.pageModel1.list_rows
				}
				this.$Apis.getMyComment( param).then(res => {
					this.loading = false
					this.comments = res.data.data
					this.pageModel1.total = res.data.total
				}).catch(() => {})
			},
			selectCommentList(val) {
				this.pageModel1.page = val
				this.getComment()
			},
			// 删除评论
			deleteComment(item) {
				let param = {
					comment_id: item.id
				}
				this.$Apis.delComment(param).then(res => {
					
					this.$Message.success('删除评论成功')
					 this.getComment()
				}).catch(() => {})
			},
			getMessage() {
				let param = {
					page:this.pageModel2.page,
					list_rows:this.pageModel2.list_rows
				}
				this.$Apis.getMyMessage( param).then(res => {
					this.loading = false
					this.messages = res.data.data
					this.pageModel2.total = res.data.total
				}).catch(() => {})
			},
			selectMessageList(val) {
				this.pageModel2.page = val
				this.getMessage()
			},
			// 删除留言
			deleteMessage(item) {
				let param = {
					message_id: item.id
				}
				this.$Apis.delMessage( param).then(res => {
					
					this.$Message.success('删除留言成功')
					  this.getMessage()
				}).catch(() => {})
			},
			handleSuccess (res, file,fileList) {
				   this.$Message.success('修改头像成功')
				   this.GetUser();
			    
			},
		},
		 
	}
</script>


<style scoped lang="scss">
	.person {
		display: flex;
		font-size: 14px;
	}

	.leftinfo {
		width: 270px;
		min-width: 270px;
		line-height: 30px;
		margin-right: 30px;
		margin-bottom: 30px;
		box-shadow: 2px 2px 14px #c0dbe6;
		height: max-content;

		.info-top {
			display: flex;
			margin-bottom: 20px;
			background: #ecf0f1;
			padding: 20px;

			img {
				width: 86px;
				height: 86px;
				border-radius: 50%;
				box-shadow: 2px 2px 14px #c0dbe6;
			}

			.top-text {
				padding-left: 20px;

				.name {
					color: #f7576c;
					font-size: 20px;
				}

				.time {
					color: #90999c;
					padding-left: 5px;
				}
			}
		}

		ul {
			margin: 20px;
			border: 1px solid #ddebf1;
			border-radius: 4px;

			li {
				line-height: 44px;
				padding: 0 20px;
				border-bottom: 1px solid #ddebf1;
			}

			li:last-child {
				border: none;
			}

			.link {
				cursor: pointer;
			}
		}
	}

	.rightmain {
		flex: 1;
		width: 0;

		>div {
			box-shadow: 2px 2px 14px #c0dbe6;
			margin-bottom: 30px;

			h6 {
				background: #ecf0f1;
				padding: 8px 20px;
				font-size: 16px;
				font-weight: 400;
			}
		}

		ul {
			padding: 5px 20px;

			li {
				padding: 10px 0 15px;
				position: relative;
				border-bottom: 1px solid #ddebf1;

				.mark {
					padding: 5px 0;
				}

				.time {
					padding-top: 5px;
					color: #858a8c;

					i {
						margin: -2px 6px 0 0;
					}
				}
			}

			li:last-child {
				border: none;
			}

			.delete {
				position: absolute;
				bottom: 13px;
				right: 0px;
				cursor: pointer;

				i {
					font-size: 20px;
					color: #98aaaf;
				}
			}
		}
	}

	.noneli {
		line-height: 35px;
	}

	@media screen and (max-width: 750px) {
		.person {
			flex-direction: column;
		}

		.leftinfo {
			flex: 1;
			width: 100%;
			margin-right: 0;
		}

		.rightmain {
			width: 100%;
		}
	}
</style>
